html {
    scroll-behavior: smooth;
}

:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    outline: 2px solid rgba(#0070F4, 0.5);
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        box-shadow: none !important;
    }
}

// Hamburger button
.hamburger {

    svg > * {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            transform-origin: center;
            transform: rotate(0deg);
        }

        &:nth-child(1) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
        }

        &:nth-child(2) {
            transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &:nth-child(3) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
        }        
    }

    &.active {

        svg > * {

            &:nth-child(1) {
                opacity: 0;
                y: 11;
                transform: rotate(225deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
            }
    
            &:nth-child(2) {
                transform: rotate(225deg);
                transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
    
            &:nth-child(3) {
                y: 11;
                transform: rotate(135deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;   
            }
        }
    }
}

.blur {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}
//Typing Animation
@keyframes typing {
    0% { 
        // opacity: 1;
        content: "exuberance";
        color:rgba(255,255,0,0.3);
        // color: #0070F4;
            }
    35% { 
    // opacity: 1;
        content: "enthusiasm";
        color:rgba(255,0,255,0.3);;
        // color: aqua;
            }
    68% { 
    // opacity: 1;
        content: "passion";
        color: rgba(0,0,255,0.3);
        }
    100% { 
    // opacity: 1;
    content: "curiosity";
    color:rgba(255, 60, 0, 0.714);
    // color: #0070F4;
    }
    // 100% {
    // // opacity: 0;
    // }

}
.fb svg:hover{
    fill: #1877F2;
}
.ig svg:hover{
    fill: #E4405F;
}
.twitter svg:hover{
    fill: #1DA1F2;
}
.linkedin svg:hover{
    fill: #0A66C2;
}

.dynamic-text::after{
    content: "passion";
    animation: typing 5s linear infinite alternate,
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
};

//spinner
.spin{
    content: "";
    -webkit-animation: spin 0.7s linear infinite;
    animation: spin 0.7s linear infinite;
};

// Colour on Hover
.display-image {
  -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%); /* FF 35+ */
}

.display-image:hover {
    transition: filter .5s ease-in-out;
    -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
    filter: grayscale(0%); /* FF 35+ */
}

// Pulsing animation
@keyframes pulseLoop {
    0% { opacity: .15; transform: scale(1) translateZ(0); }
    30% { opacity: .15; }
    60% { opacity: 0; }
    80% { opacity: 0; transform: scale(1.8) translateZ(0); }
}
@keyframes pulseMiniLoop {
    0% { opacity: 0; transform: scale(1) translateZ(0); }
    30% { opacity: .3; }
    50% { opacity: .3; }
    80% { opacity: 0; transform: scale(3) translateZ(0); }
}

.pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 10000ms linear infinite;                                   
}
.pulse-mini {
    animation: pulseMiniLoop 6000ms linear infinite;                                   
}
.pulse-1 {
    animation-delay: -3000ms;
}
.pulse-2 {
    animation-delay: -6000ms;
}

// Animations delay
.animation-delay-500 {
    animation-delay: 500ms !important;
}

.animation-delay-1000 {
    animation-delay: 1000ms !important;
}

.translate-z-0 {
    transform: translateZ(0);
}

// Custom AOS animations
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}