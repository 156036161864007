.ng-scope {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

p {
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
}

h2 {
    font-size: 38px;
    line-height: 1.5;
    font-family: din-next-light,sans-serif;
}
h3 {
font-size: 20px;
color: black;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: black;
}

.generic-body ol {
    padding-top: 15px;
    padding-bottom: 25px;
    padding-left: 28px;
}
.generic-body__title {
    padding-top: 56px;
    padding-bottom: 25px;
}
ul {
    color: black;
}

/* a, li, p, span {
    font-size: 18px;
    line-height: 1.5;
    font-family: din-next-regular,Helvetica,sans-serif;
    color: #919191;
} */