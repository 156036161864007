
.body {
    display: block;
    margin: 8px;
}

.toc {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 15px;
}

.toc li {
    font-size: 1.04em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    padding-top: 17px;
    padding-bottom: 17px;
    text-transform: uppercase;
}

.content {
    padding-left: 26px;
    padding-right: 26px;
}

.privacy{
    font-size: 0.94em;
    /* font-family: 'graphik-regular'; */
    color: #888989;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 25px;
    padding-top: 25px;
}

p, ol, ul {
    font-size: 1em;
    font-family: 'graphik-regular';
    color: #888989;
    line-height: 1.5em;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h3 {
    font-size: 1.08em;
    font-family: 'graphik-regular';
    color: #0A0A0B;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
}
strong {
    font-weight: bold;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

ul li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }

  

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


